/* Berita CONTENT */
.head-container .head-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--color-8);
  text-align: center;
  color: var(--color-3);
  font-family: var(--font-merriweather);
  justify-content: center;
  padding: 20px;
}

.head-box .head-title {
  font-size: 1.8rem;
  font-weight: 800;
}

.head-box .head-subtitle {
  font-size: 0.8rem;
  font-weight: 600;
}

.daftar-berita-container .item-box__title,
.daftar-berita-container .item-box__subtitle {
  text-align: start;
}

.news-detail-thumb img {
  width: 240px;
}

.news-detail-date {
  margin-top: 18px;
  color: rgb(170, 170, 170);
  line-height: 1;
}

.news-detail-author {
  font-size: small;
}

.news-detail-author span {
  color: rgb(97, 195, 241);
  font-weight: 600;
  font-size: medium;
}

/* RESPONSIVE */
@media (min-width: 576px) {
  .news-detail-thumb img {
    width: 540px;
  }
}

@media (min-width: 768px) {
  .news-detail-thumb img {
    width: 640px;
  }
}
