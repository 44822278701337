/* ABOUT CONTENT */
.head-container {
  height: 320px;
  margin-top: var(--navbar-height) !important;
  background-color: var(--color-8);
}

.head-container .head-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--color-8);
  text-align: center;
  color: var(--color-3);
  font-family: var(--font-merriweather);
  justify-content: center;
  padding: 20px;
}

.head-box .head-title {
  font-size: 1.8rem;
  font-weight: 800;
}

.head-box .head-subtitle {
  font-size: 0.8rem;
  font-weight: 600;
}

.program-studi-container .item-box__title,
.program-studi-container .item-box__subtitle {
  text-align: start;
}

.item-box.beasiswa-box {
  background: url("../assets/img/Bg-Box.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 500px;
}
