@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");

:root {
  --main-color: #ffffff;
  --background-color: #f3f3f3;
  --color-1: #fff76a;
  --color-2: #037303;
  --color-3: #000000;
  --color-4: #e1e1e1;
  --color-5: #6b6b6b;
  --color-6: #27282a;
  --color-7: #222324;
  --color-8: #eaeaea;

  --navbar-height: 100px;

  --font-merriweather: "Merriweather", serif;
  --font-roboto: "Roboto", sans-serif;
}

html,
body {
  background-color: var(--background-color) !important;
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
  color: unset !important;
}

/* TEMPLATE */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.full-container {
  background-color: var(--background-color);
  max-width: 100% !important;
  margin: 0px;
  padding: 0px !important;
}

.group-box {
  display: flex;
  flex-direction: column;
  background-color: unset !important;
  padding: 0px !important;
}

.group-box .item-box__child {
  background-color: var(--main-color);
  margin: 0px auto 50px auto !important;
  width: 100%;
  padding: 25px;
}

.group-box .item-box__child:last-child {
  margin: 0px auto !important;
}

.container-box__item {
  position: relative;
  top: -50px;
}

.item-box {
  background-color: var(--main-color);
  width: 100%;
  margin: 0px auto;
  text-align: center;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.item-box.box-image {
  display: flex;
  flex-direction: column;
}

.item-box.card-overlap__style {
  width: 90%;
  margin: 0px auto;
  position: relative;
  top: -50px;
  text-align: center;
  max-width: 1320px;
}

.item-box.card-normal__style {
  width: 90%;
  margin: 50px auto;
  max-width: 1320px;
  text-align: center;
}

.item-box__content {
  width: 100%;
}

.item-box__thumb {
  /* background-color: var(--color-3); */
  padding: 10px;
  position: relative;
  top: -25px;
}

.item-box__thumb img {
  width: 100%;
}

.item-box__title {
  padding: 5px 0px;
  margin: 0px auto;
}

.item-box__title.text-border {
  border-bottom: 4px solid var(--color-1);
}

.item-box__title.text-border.col-black {
  border-bottom: 4px solid var(--color-3);
}

.item-box__title span {
  font-size: 1.3rem;
  font-family: var(--font-merriweather);
  font-weight: 800;
}

.item-box__subtitle {
  margin-top: 30px;
  font-family: var(--font-roboto);
}

.button-container {
  font-family: var(--font-roboto);
  font-size: 0.9rem;
  margin: 30px auto;
}

.button-container button {
  background-color: #00000000;
  border: none;
  box-shadow: none;
}

.button-container span {
  border: 2px solid var(--color-3);
  padding: 8px 20px;
  cursor: pointer;
}

.button-container span:hover {
  color: var(--main-color);
  background-color: var(--color-3);
}

.button-container.v2 span:hover {
  color: var(--color-1);
  background-color: var(--color-3);
}

.button-container span:focus {
  color: var(--main-color);
  background-color: var(--color-3);
}

.button-container.v2 span:focus {
  color: var(--color-1);
  background-color: var(--color-3);
}

.button-container span::after {
  color: var(--main-color);
  background-color: var(--color-3);
}

.button-container.v2 span::after {
  color: var(--color-1);
  background-color: var(--color-3);
}

/* NAVBAR START */
.navbar {
  height: var(--navbar-height);
  background-color: var(--main-color);
  font-family: var(--font-roboto);
  font-weight: 500;
  font-size: 1rem;
  color: var(--color-5);
  padding: 0px !important;
  z-index: 1000;
}

.navbar.move {
  box-shadow: 0px 11px 22px -10px rgba(0, 0, 0, 0.25);
}

.navbar .nav-item {
  margin: 0px 5px;
  padding: 0px;
  border-bottom: 3px solid var(--main-color);
  display: none;
}

.navbar .nav-item span {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .nav-item::after {
  display: block;
  content: "";
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
  border-bottom: 3px solid var(--color-2);
}

.navbar .nav-item:hover {
  color: var(--color-2);
}

.navbar .nav-item:hover::after {
  transform: scaleX(1);
}

.navbar .nav-item:focus {
  color: var(--color-2);
  border-bottom: 3px solid var(--color-2);
}

.navbar .nav-item.active {
  color: var(--color-2) !important;
  border-bottom: 3px solid var(--color-2);
}

.navbar__container {
  height: 100%;
}

.navbar .navbar-nav {
  height: 100%;
}

/* Burger Bars Start */
.bar1,
.bar2,
.bar3 {
  height: 3px;
  border-radius: 1px;
  background: var(--color-5);
  margin-bottom: 3px;
  border-radius: 100px;
}

.bar1 {
  width: 20px;
}

.bar2 {
  width: 15px;
}

.bar3 {
  width: 10px;
}

li.bars {
  list-style: none;
  cursor: pointer;
  margin: 30px 5px;
  padding: 8px 5px 3px;
  border: 2px solid var(--color-5);
  border-radius: 5px;
}

li.bars.open {
  border: 2px solid var(--color-2);
}

li.bars:hover {
  border-radius: 5px;
  border: 2px solid var(--color-2);
}

li.bars:hover > div {
  background-color: var(--color-2);
}

li.bars .bar1 {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
li.bars .bar2 {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
li.bars .bar3 {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.bars.open {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.bars.open .bar1 {
  background-color: var(--color-2);
  -moz-transform: rotate(45deg) translate(8px, 7px);
  -o-transform: rotate(45deg) translate(8px, 7px);
  -ms-transform: rotate(45deg) translate(8px, 7px);
  -webkit-transform: rotate(45deg) translate(8px, 7px);
  transform: rotate(45deg) translate(4px, 4px);
}
.bars.open .bar2 {
  opacity: 0;
}
.bars.open .bar3 {
  background-color: var(--color-2);
  width: 20px;
  -moz-transform: rotate(-45deg) translate(7px, -6px);
  -o-transform: rotate(-45deg) translate(7px, -6px);
  -ms-transform: rotate(-45deg) translate(7px, -6px);
  -webkit-transform: rotate(-45deg) translate(7px, -6px);
  transform: rotate(-45deg) translate(5px, -5px);
}
/* Burger Bars End */

/* Dropdown Menu Start */
.navbar-dropdown {
  position: fixed;
  background-color: var(--color-4);
  width: 100%;
}

.navbar_dropdown_container {
  margin: 20px 20px 80px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
}

.navbar-dropdown.close {
  opacity: 0;
  transition: all 0.5s ease-in-out;
  height: 0px;
  top: -1000px;
}

.navbar-dropdown.open {
  opacity: 1;
  top: calc(var(--navbar-height));
  transition: all 0.5s ease-in-out;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 100px;
  z-index: 999;
}

.dorpdown-item_box {
  width: 140px;
}

.dorpdown-item_box .nav-link {
  margin-bottom: 7px;
}

.dorpdown-item__title {
  font-size: 0.8rem;
}

.dorpdown-item__title.header {
  font-weight: 700;
  color: var(--color-2);
}
/* Dropdown Menu End */
/* NAVBAR END */

/* FOOTER START */
.footer-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  color: var(--main-color);
  font-family: var(--font-roboto);
  margin-top: auto;
}

.footer-container .footer-box {
  background-color: var(--color-6);
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-bottom: 2px solid var(--color-5);
}

.footer-container .footer-box__container {
  background-color: var(--color-6);
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.footer-box .footer-item {
  text-align: center;
  margin: 20px auto;
}

.footer-box .footer-item.logo {
  background-color: white;
  padding-top: 18px;
  border-radius: 14px;
  width: 160px;
  height: 160px;
}

.footer-item__title {
  font-weight: 800;
  margin-bottom: 5px;
}

.footer-box .footer-item img {
  width: 150px;
}

.footer-container .footer-cipta {
  background-color: var(--color-7);
  text-align: center;
  padding: 10px;
  font-weight: 300;
  font-size: 0.8rem;
}

.footer-item .sosmed-container {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
/* FOOTER END */

/* RESPONSIVE */
@media (min-width: 576px) {
  /* Dropdown */
  .navbar_dropdown_container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 40px 35px;
    column-gap: 20px;
  }

  .dorpdown-item_box {
    width: 170px;
  }
}
@media (min-width: 680px) {
  /* Content */
  .group-box {
    flex-direction: row;
    column-gap: 20px;
  }

  .group-box .item-box__child {
    margin: 0px auto !important;
  }

  .item-box.box-image {
    flex-direction: row;
    column-gap: 20px;
    text-align: start;
    justify-content: space-between;
  }

  .struktur-org-container .item-box.box-image {
    flex-direction: row-reverse !important;
  }
}

@media (min-width: 768px) {
  /* Footer */
  .footer-container .footer-box__container {
    flex-direction: row;
    max-width: 1320px;
    column-gap: 40px;
  }

  .footer-box .footer-item {
    height: 150px;
  }

  .footer-box .footer-item img {
    width: 150px;
  }

  .footer-box .footer-item.border-item {
    border-right: 1px solid var(--color-5);
    border-left: 1px solid var(--color-5);
    padding: 0px 30px;
  }

  .item-box__thumb img {
    width: 260px;
  }
}

@media (min-width: 820px) {
  /* Dropdown */
  .navbar_dropdown_container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 80%;
    align-content: center;
    margin: 40px auto;
  }

  .dorpdown-item__title {
    font-size: 1rem;
  }
}

@media (min-width: 993px) {
  /* Navbar */
  .navbar .nav-item {
    display: inline-block;
  }
}

@media (min-width: 1400px) {
  /* Dropdown */
  .navbar_dropdown_container {
    width: 1300px;
    column-gap: 20px;
  }
}
