/* ERROR CONTENT */
.error-container {
  height: 320px;
  margin-top: var(--navbar-height) !important;
  background-color: var(--color-8);
}

.error-container .error-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--color-8);
  text-align: center;
  color: var(--color-3);
  font-family: var(--font-merriweather);
  justify-content: center;
  padding: 20px;
}

.error-box .error-title {
  font-size: 1.8rem;
  font-weight: 800;
}

.error-box .error-subtitle {
  font-size: 0.8rem;
  font-weight: 600;
}
