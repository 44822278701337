/* Penelitian CONTENT */
.head-container {
  height: 320px;
  margin-top: var(--navbar-height) !important;
  background-color: var(--color-8);
}

.head-container .head-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--color-8);
  text-align: center;
  color: var(--color-3);
  font-family: var(--font-merriweather);
  justify-content: center;
  padding: 20px;
}

.head-box .head-title {
  font-size: 1.8rem;
  font-weight: 800;
}

.head-box .head-subtitle {
  font-size: 0.8rem;
  font-weight: 600;
}

.penelitian-container .item-box__title,
.penelitian-container .item-box__subtitle {
  text-align: start;
}

.jurnal-item__container {
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.jurnal-item {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}

.jurnal-item .jurnal-thumb {
  overflow: hidden;
}

.jurnal-item .jurnal-thumb img {
  width: 100%;
}

.jurnal-item .jurnal-title {
  font-family: var(--font-roboto);
  text-align: start;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.2;
  margin: 2px 0px;
}

.jurnal-item .jurnal-desc {
  display: block;
  font-family: var(--font-roboto);
  text-align: start;
  font-size: 0.8rem;
  max-height: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

@media (min-width: 576px) {
  /* Content jurnal */
  .jurnal-item__container {
    padding: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
  }
}

@media (min-width: 993px) {
  /* jurnal Content */
  .jurnal-item__container {
    max-width: 1200px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 50px;
    margin: auto;
  }
}
