/* Alumni CONTENT */
.head-container .head-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--color-8);
  text-align: center;
  color: var(--color-3);
  font-family: var(--font-merriweather);
  justify-content: center;
  padding: 20px;
}

.head-box .head-title {
  font-size: 1.8rem;
  font-weight: 800;
}

.head-box .head-subtitle {
  font-size: 0.8rem;
  font-weight: 600;
}

.alumni-container .item-box__title,
.alumni-container .item-box__subtitle {
  text-align: start;
}
