/* BANNER START */
.banner-container {
  height: 480px;
  background: url("../assets/img/Banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: var(--navbar-height) !important;
}

.banner-container .banner-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  color: var(--main-color);
  font-family: var(--font-merriweather);
  justify-content: center;
}

.banner-box .banner-title {
  font-size: 1.5rem;
  font-weight: 800;
  position: relative;
  top: -30px;
}

.banner-box .banner-subtitle {
  font-size: 0.8rem;
  font-weight: 600;
  position: relative;
  top: -30px;
}
/* BANNER END */

/* CONTENT START */
.intro-video {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

/* News Start */
.news-item__container {
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.news-item {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}

.news-item .news-thumb {
  overflow: hidden;
}

.news-item .news-thumb img {
  width: 100%;
  transition: 0.5s all ease-in-out;
}

.news-item:hover > .news-thumb img {
  transform: scale(1.5);
}

.news-item .news-title {
  font-family: var(--font-roboto);
  text-align: start;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  margin: 2px 0px;
}

.news-item .news-date {
  font-family: var(--font-roboto);
  text-align: start;
  font-size: 0.6rem;
}
/* News End */

/* Agenda Start */
.agenda-item__container {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

.agenda-item {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  width: 100%;
  max-width: 300px;
}

.agenda-item .agenda-thumb_box {
  width: 60px;
  background-color: var(--color-1);
  display: flex;
  flex-direction: column;
  line-height: 1;
  padding: 8px;
  margin: auto 0px;
}

.agenda-thumb_box .agenda-date {
  font-weight: 700;
  font-size: 1.2rem;
}
.agenda-thumb_box .agenda-month {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--color-2);
}
.agenda-thumb_box .agenda-day {
  font-size: 0.6rem;
}

.agenda-title {
  line-height: 1.2;
  text-align: start;
  margin: auto 0px;
}
/* Agenda End */

/* Beasiswa Start */
.item-box.beasiswa-box {
  background: url("../assets/img/Bg-Box.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 500px;
}
/* Beasiswa Start */
/* CONTENT END */

/* RESPONSIVE */
@media (min-width: 576px) {
  /* Banner */
  .banner-box .banner-title {
    font-size: 2rem;
  }

  .banner-box .banner-subtitle {
    font-size: 1.3rem;
  }

  /* Content News */
  .news-item__container {
    padding: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
  }

  .news-item .news-date {
    font-size: 0.8rem;
  }

  .intro-video {
    height: 400px;
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .agenda-item__container {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }
}

@media (min-width: 820px) {
}

@media (min-width: 993px) {
  .intro-video {
    height: 500px;
  }
  /* News Content */
  .news-item__container {
    max-width: 1200px;
    grid-column-gap: 50px;
    margin: auto;
  }

  /* Content Agenda */
  .agenda-item__container {
    padding: 20px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1400px) {
}
