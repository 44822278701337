/* Calender Box Start */
.calender-box {
  width: 250px;
  margin: 0px auto;
  background-color: var(--main-color);
  border-radius: 10px;
  box-shadow: 2px 3px 4px 3px rgba(0, 0, 0, 0.068);
  -webkit-box-shadow: 2px 3px 4px 3px rgba(0, 0, 0, 0.068);
  -moz-box-shadow: 2px 3px 4px 3px rgba(0, 0, 0, 0.068);
}

.calender-box header {
  display: flex;
  align-items: center;
  padding: 25px 30px 10px;
  justify-content: space-between;
}

.calender-box .current-date {
  margin-bottom: 0px;
  font-size: 1.25rem;
  font-weight: 500;
}

.calender-box header .icons svg {
  margin: 0px 5px;
  height: 32px;
  width: 32px;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.calender-box header .icons svg:hover {
  background-color: var(--background-color);
}

.calender-box header .icons svg:last-child {
  margin-right: -10px;
}

.calender {
  padding: 20px;
}

.calender ul {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  text-align: center;
  padding: 0px !important;
}

.calender .days {
  margin-bottom: 20px;
}

.calender .weeks li {
  font-weight: 500;
  font-size: 0.85rem;
}

.calender ul li {
  position: relative;
  width: calc(100% / 7);
}

.calender .days li {
  z-index: 1;
  cursor: pointer;
  margin-top: 30px;
  font-size: 0.85rem;
}

.calender .days li.inactive {
  color: var(--color-4);
}

.calender .days li.active {
  color: var(--main-color);
}

.calender .days li.active::before {
  background-color: var(--color-2);
}

.calender .days li::before {
  position: absolute;
  content: "";
  height: 27px;
  width: 27px;
  top: 50%;
  left: 50%;
  z-index: -1;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.days li:hover::before {
  background-color: var(--background-color);
}
/* Calender Box End */

/* Event Box Start */
/* Event Box End */

/* RESPONSIVE */
@media (min-width: 576px) {
  .calender-box {
    width: 450px;
  }

  .calender-box .current-date {
    font-size: 1.45rem;
  }

  .calender-box header .icons svg {
    height: 38px;
    width: 38px;
  }

  .calender .weeks li {
    font-size: 1rem;
  }

  .calender .days li {
    font-size: 1rem;
  }

  .calender .days li::before {
    position: absolute;
    content: "";
    height: 40px;
    width: 40px;
    top: 50%;
    left: 50%;
    z-index: -1;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (min-width: 680px) {
}

@media (min-width: 768px) {
}

@media (min-width: 820px) {
}

@media (min-width: 993px) {
}

@media (min-width: 1400px) {
}
