/* Info Jalur Masuk CONTENT */

.head-container .head-box.pmb-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* background-image: url("../../assets/img/pmb.jpg");
  background-size: cover; */
  background-color: var(--color-8);
  text-align: center;
  color: var(--color-3);
  font-family: var(--font-merriweather);
  justify-content: center;
  padding: 20px;
}

.head-box .head-title {
  font-size: 1.8rem;
  font-weight: 800;
}

.head-box .head-subtitle {
  font-size: 0.8rem;
  font-weight: 600;
}

.item-box__banner {
  /* height: 100px; */
  width: 100%;
}

.item-box__title {
  font-size: 1.8rem;
  font-weight: 600;
}

.item-box__cards {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-auto-rows: auto;
  justify-items: center;
}

.item-box__card h4 {
  margin-bottom: 1px;
}

.item-box__cards .item-box__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  padding: 24px 12px;
  width: fit-content;
  max-width: 400px;
  height: 100%;
}

.item-box__regist {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 24px;
}

.item-box__regist img {
  width: 100%;
}

@media (min-width: 768px) {
  .item-box__regist img {
    width: 50%;
  }
}

@media (min-width: 993px) {
  .item-box__regist {
    flex-direction: row;
  }

  .item-box__cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .item-box__cards .item-box__card {
    max-width: none;
  }
}
